import Cards from "ab-cards";

const Disfrute = ({ classname, category, moduloCross, i18n }) => {
  let rowArray = [];
  var i,
    j,
    temporary,
    chunk = 3;
  for (i = 0, j = category?.products?.nodes?.length; i < j; i += chunk) {
    temporary = category?.products?.nodes.slice(i, i + chunk);
    rowArray.push(temporary);
  }

  let disfrute = moduloCross?.modCrossDisfrute;

  // console.log("Disfrute", disfrute);

  return (
    <Cards
      className={
        classname ? classname : rowArray.length % 2 == 1 ? "beige" : "white"
      }
      textStyle={{
        maxWidth: 455,
      }}
      {...disfrute}
      title={
        i18n.language == "en"
          ? disfrute?.titleEn || disfrute?.title
          : i18n.language == "de"
          ? disfrute?.titleDe || disfrute?.title
          : disfrute?.title
      }
      i18n={i18n}
    />
  );
};

export default Disfrute;
